/** Dependencies */
import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import WindowButton from './../../WindowButton/WindowButton';

/** Redux */
import store from './../../../store';

/** Components */
import ActionMenu from '../../Menus/ActionMenu/ActionMenu';

/** Helpers */
import { isNullOrUndefined } from '../../../helpers/functions';
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './GridCellStatusAction.scss';

function GridCellStatusAction( props )
{
  const{
    id,
    index,
    pageSize,
    statusId,
    statusDatas,
    label,
    loadingParams,
    reloadGridCallBackFct
  } = props;

  /** Define colors */
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /**
   * Return status label and picto label for status id
   * @param {int} statusId 
   * @returns Object
   */
  const currentStatusDatas = statusDatas.find( elem => elem.id === statusId );  

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'status-action-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );
  
  // render content
  root.render
  (
    <Fragment>

      {/* Status picto */}
      {
        !isNullOrUndefined( currentStatusDatas ) ?
          <span 
            title={ currentStatusDatas.label }
          >
            { getPicto( currentStatusDatas.pictoLabel, { size: '1.3rem', color: blueColor } ) }
          </span> 
          : null
      }

      <Provider store={ store }>
        <WindowButton
          id={ 'status-action-' + id }
          className="status-action"
          windowContent={ 
            <ActionMenu 
              labels= { label }
              statusId= { !isNullOrUndefined( currentStatusDatas ) ? currentStatusDatas.id : undefined }
              loadingParams={ loadingParams }              
              reloadGridCallBackFct={ reloadGridCallBackFct }
            /> 
          }
          buttonContent={ getPicto( 'Control', { size: '1rem', weight: 'bold', fill: grey2Color } ) }
          mouseEventType="click"
          positionClassName={ ( ( index + 1 ) / pageSize ) % 1 > 0.75 || Number.isInteger( ( index + 1 ) / pageSize ) ? 'bottom' : 'top' }
        />
      </Provider>

    </Fragment>
  );

  return rootElement;
} 

export default GridCellStatusAction;