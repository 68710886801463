/** Dependencies */
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'smart-webcomponents-react/chart';

/** JSON */
import i18n from './../../assets/json/i18n.json';

/** Helpers */
import { getMinMaxFromArrayObject } from './../../helpers/functions';

/** SCSS */
import './InsightsCompareMultiLineChart.scss';

function InsightsCompareMultiLineChart( props )
{
  const {
    id,
    datas,
    series,
    dataField,
    flip,
    xAxisPosition,
    xAxisLabelVisible,
    mouseOverCallbackFct,
    highlightedValues
  } = props;
  
  // Define ref
  const chartRef = useRef( null );
  
  // Define positive and negitive colors
  const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();

  /** Get label from i18n json file */
  const rangesLabels = i18n.fr.topRangeLabels;

  // get min / max values from compare and current datas
  let minMaxValues = null;
  if( datas[0] )
    minMaxValues = getMinMaxFromArrayObject( datas, Object.keys( datas[0] ).filter( elem => elem !== 'xaxis' ) );

  /** Init State */
  const [ mouseOverDetails, setMouseOverDetails ] = useState( null );
  const [ chart, setChart ] = useState( null );

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    columnSeriesOverlap: true,
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => 
      xAxisValue 
      + ' : ' 
      + ( rangesLabels[serie.dataField] !== undefined ? rangesLabels[serie.dataField] : serie.dataField ) 
      + ' : ' 
      +  Intl.NumberFormat('fr-FR').format( value ),
    enableCrosshairs: true,
    padding: {
      left: 0,
      top: 5,
      right: 0,
      bottom: 5
    },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: datas,
    xAxis: {
      dataField: dataField,
      position: xAxisPosition !== undefined ? xAxisPosition : 'bottom',
      tickMarks: {
        visible: false
      },
      labels: {
        visible: xAxisLabelVisible !== undefined ? xAxisLabelVisible : true,
        class: 'x-axis-labels',        
        offset: {
          y: flip?.valueAxis !== undefined ? -5 : 5
        }
      },
      gridLines: {
        color: gridLinesColor
      },
      valuesOnTicks: false
    },
    seriesGroups: [      
      {
        type: 'line',
        valueAxis: {
          axisSize: 30,
          visible: true,
          minValue: 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max + 2 : 100,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
          gridLines: {
            dashStyle: '4,4',
            color: gridLinesColor
          },
          tickMarks: {
            visible: false
          },
          labels: {
            class: 'values-axis-labels'
          }
        },
        series: series
      }
    ]
  };

  /** Init Chart */
  useEffect( () => 
  {
    if( chart === null )
      setChart(
        <Chart
          {...settings}
          ref={ chartRef }
          id = { id + '-chart' }
          onMouseOver={ e => 
            e?.detail?.event !== undefined ? 
              setMouseOverDetails({
                groupIndex: 0, 
                serieValue: e.detail.serie.dataField,
                itemIndex: e.detail.elementIndex
              }) 
              : null
          }
        />
      );

  }, []);

  useEffect( () => 
  {
    mouseOverCallbackFct( mouseOverDetails );

  }, [ JSON.stringify( mouseOverDetails ) ]);

  useEffect( () => 
  {
    if( 
      highlightedValues !== undefined 
      && highlightedValues !== null
    ){
      chartRef.current.showToolTip(
        highlightedValues.groupIndex,
        series.findIndex( serie => serie.dataField === highlightedValues.serieValue ),
        highlightedValues.itemIndex
      );
    }
  }, [ JSON.stringify( highlightedValues ) ]);
  
  return(
    <div className="insights-line-chart">
      { chart }
    </div>
  )
}

export default InsightsCompareMultiLineChart;