/** Dependencies */
import { Button } from 'smart-webcomponents-react/button';

/** SCSS */
import './AreYouSure.scss';

function AreYouSure( props )
{
  const 
  {
    text,
    cancelCallBackFct,
    confirmCallBackFct,
    isOpened
  } = props;

  return(
    <div className={ isOpened ? 'areyousure-container' : 'areyousure-container hide' }>
      <p>{ text }</p>
      <div className='confirm-container'>
        <Button 
          className='flat empty'
          onClick={ cancelCallBackFct }
        >Annuler</Button>

        <Button 
          className='flat fill' 
          onClick={ confirmCallBackFct } 
        >Confirmer</Button>
      </div>
    </div>
  )
}

export default AreYouSure;