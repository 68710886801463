/** Dependencies */
import React, { Fragment } from 'react';

/** Components */
import InsightsCompareValues from './InsightsCompareValues';

/** Helpers */
import { getSumFromArrayOfObject } from './../../helpers/functions';

/** SCSS */
import './InsightsListValues.scss';

function InsightsListValues( props )
{
  const {
    values,
    unit,
    totalDisplay
  } = props;

  return(
    <Fragment>
    {
      <ul className='insights-list'>

        {/* Values */}
        { values.filter( elem => elem !== undefined ).map( ( value, index ) => 
          value?.label !== undefined ?
            <li 
              key={ index }
              className={ value?.className !== undefined ? value.className : '' }
            >
              <div className='picto-label'>
                { value?.picto ? value.picto : null }<span className='label' title={ value.label }>{ value.label }</span>
              </div>
              <InsightsCompareValues currentValue = { value.value } unit={ unit } notation={ 'compact' } />
            </li>
            : null
        )}

        {/* Total value */}
        { totalDisplay ?
          <li className='total'>
            <div className='picto-label'><b>Total</b></div>
            <InsightsCompareValues currentValue = { getSumFromArrayOfObject( values.filter( elem => elem !== undefined ), 'value' ) } unit={ unit } notation={ 'compact' } />
          </li>
          : null
        }

      </ul>
    }
    </Fragment>
  )
}

export default InsightsListValues;