/** Dependencies */
import React, { Fragment } from 'react';

/** Helpers */
import { getPicto } from './../../helpers/pictos';

/** SCSS */
import './InsightsCompareValues.scss';

function InsightsCompareValues( props )
{
  const {
    currentValue,
    compareValue,
    variation,
    unit,
    notation,
    style
  } = props;

  let {
    classStyle
  } = props;

  // Define increase and decrease colors
  const increaseColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();
  const decreaseColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-orange').trim();
  const constantColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-yellow').trim();

  // Format value
  const currentFormatValue = ( currentValue !== undefined && currentValue !== null && currentValue !== '' ) ? 
    Intl.NumberFormat('fr-FR', { 
      maximumFractionDigits: currentValue < 1 ? 2 : 1, 
      notation: notation !== undefined ? notation : 'standard',
      style: style !== undefined ? style : 'decimal',
    }).format( currentValue ) 
    : null;
  
  const compareFormatValue = ( compareValue !== undefined && compareValue !== null && compareValue !== '' ) ? 
    Intl.NumberFormat('fr-FR', { 
      maximumFractionDigits: compareValue < 1 ? 2 : 1, 
      notation: notation !== undefined ? notation : 'standard',
      style: style !== undefined ? style : 'decimal',
    }).format( compareValue ) 
    : null;

  const variationFormatValue = ( variation !== undefined && variation !== null && variation !== '' ) ? 
    Intl.NumberFormat('fr-FR', { 
      maximumFractionDigits: variation < 1 ? 2 : 1, 
      notation: notation !== undefined ? notation : 'standard',
      signDisplay: 'exceptZero' 
    }).format( variation ) 
    : null;

  // get if positiv or negativ trend value
  let positivValue = null;
  if( variationFormatValue !== null )
  {
    if( variationFormatValue.substring( 0, 1 ) === '+' )
      positivValue = true;
    else if( variationFormatValue.substring( 0, 1 ) === '-' )
      positivValue = false;    
  }

  return(
    <Fragment>
    {
      (
        currentFormatValue !== null
        || compareFormatValue !== null
        || variationFormatValue !== null
      ) ?    
        <div className={ classStyle !== undefined ? 'insights-compare-trend ' + classStyle : 'insights-compare-trend' }>

          { currentFormatValue !== null ? 
            <div className={ positivValue === false ? 'current negativ' : 'current' }>{ currentFormatValue }{ unit !== undefined ? unit : '' }</div> 
            : null 
          }
          
          { compareFormatValue !== null || variationFormatValue !== null ?
            <div className='compare-variation'>
              { compareFormatValue !== null ? <div className="compare">{ compareFormatValue }{ unit !== undefined ? unit : '' }</div> : null }
              { variationFormatValue !== null ?
                <div className="variation">
                  {
                    positivValue === true ? 
                      <React.Fragment>
                        { getPicto( 'ArrowUpRight', { size: '0.9rem', weight: 'bold', color: increaseColor } ) }
                        <span>{ variationFormatValue }{ unit !== undefined ? unit : '' }</span>
                      </React.Fragment>
                    : positivValue === false ? 
                      <React.Fragment>
                        { getPicto( 'ArrowDownRight', { size: '0.9rem', weight: 'bold', color: decreaseColor } ) }
                        <span><span className='decrease'>{ variationFormatValue }{ unit !== undefined ? unit : '' }</span></span>
                      </React.Fragment>
                    : <React.Fragment>
                        { getPicto( 'ArrowRight', { size: '0.9rem', weight: 'bold', color: constantColor } ) }
                        <span><span className='constant'>{ 0 }</span></span>
                      </React.Fragment>
                  }
                </div>
                : null
              }
            </div>
            : null
          }

        </div>
      : null
    }
    </Fragment>
  )
}

export default InsightsCompareValues;