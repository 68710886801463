/** Dependencies */
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

/** Components */
import FilterRemoverPlainText from './FilterRemoverPlainText';
import FilterRemoverRange from './FilterRemoverRange';
import FilterRemoverPicto from './FilterRemoverPicto';
import FilterRemoverSnippets from './FilterRemoverSnippets';
import FilterRemoverCategories from './FilterRemoverCategories';
import FilterRemoverAll from './FilterRemoverAll';

/** Helpers */
import { updateURLSearchParams } from './../../helpers/functions';

/** SCSS */
import './FiltersRemovers.scss';

function FiltersRemover()
{
  /** Init State */
  const [ currentFilters, setCurrentFilters ] = useState( null )

  /** UseSearchParams Hook */ 
  const [ searchParams, setSearchParams ] = useSearchParams();

  const removeFilter = ( filterName, field ) => 
  {
    // get current filter 
    const removedFiltersIndex = currentFilters.findIndex( filter => filter.filterName === filterName && filter.values[field] );

    // clone currentFilters
    let cloneCurrentFilters = structuredClone( currentFilters );

    // case of multiple filters on same dataField
    if( Object.keys( currentFilters[ removedFiltersIndex ].values ).length > 1 )
    {
      // remove value with field
      if( cloneCurrentFilters[ removedFiltersIndex ].values[field] )
        delete cloneCurrentFilters[ removedFiltersIndex ].values[field];

      // update search params
      updateURLSearchParams( { 
        filters: JSON.stringify( cloneCurrentFilters ) 
      }, [ searchParams, setSearchParams ] );

    // case of unique filter on same dataField
    } else {

      // remove filter from currentFilters
      if( cloneCurrentFilters.length === 1 )
        cloneCurrentFilters = null;
      else
        cloneCurrentFilters.splice( removedFiltersIndex, 1 );

      // update search params
      updateURLSearchParams( { 
        filters: cloneCurrentFilters !== null ? 
          JSON.stringify( cloneCurrentFilters ) 
          : null 
      }, [ searchParams, setSearchParams ] );
    }
  } 

  const updateFilterValues = ( filterName, field, originalValue, removedValue ) => 
  {
    // get current filter 
    const updatedFiltersIndex = currentFilters.findIndex( filter => filter.filterName === filterName && filter.values[field] );

    // get original filter values
    let originalFilterValues = null;
    if( originalValue.values )
      originalFilterValues = originalValue.values;

    if( originalFilterValues !== null )
    {
      // clone currentFilters
      let cloneCurrentFilters = structuredClone( currentFilters );

      // remove removed value from original filter values
      const newValues = originalFilterValues.filter( value => 
        (
          value[field] === undefined
          && value !== removedValue 
        ) || (
          value[field] !== undefined
          && value[field] !== removedValue 
        )
      )
      
      // replace with new values
      if( newValues.length > 0 )
      {
        cloneCurrentFilters[updatedFiltersIndex].values[field].values = newValues;

        // update search params
        updateURLSearchParams( { 
          filters: JSON.stringify( cloneCurrentFilters ) 
        }, [ searchParams, setSearchParams ] );

      // remove filter if empty
      } else
        removeFilter( filterName, field );
    }
  }

  const removeAllFilters = () => 
  {
    // remove filters param from url
    updateURLSearchParams( { filters: null }, [ searchParams, setSearchParams ] );
  }

  useEffect( () => 
  {
    if( searchParams.get( 'filters' ) !== null )
      setCurrentFilters( JSON.parse( searchParams.get( 'filters' ) ) );
    else
      setCurrentFilters( [] );

  }, [ searchParams.get( 'filters' ) ]);

  return (
    currentFilters !== null && currentFilters.length > 0 ?
      <div className="filters-removers">
        {
          currentFilters.map( filter => 
            Object.keys( filter.values ).map( ( valuesKey, index ) => 

              // range type filter
              (
                filter.types[valuesKey] === 'range'
                || filter.types[valuesKey] === 'range-position'
              ) ?
                <FilterRemoverRange 
                  key={ index }
                  label={ filter.label }
                  field={ valuesKey }
                  value={ filter.values[valuesKey] }
                  filterName={ filter.filterName }
                  callBackFunction={ removeFilter }
                />                  

              // plaintext type filter
              : (
                filter.types[valuesKey] === 'string' 
                || filter.types[valuesKey] === 'string-in-array' 
                || filter.types[valuesKey] === 'keywords-groups' 
              ) ?
                <FilterRemoverPlainText 
                  key={ index }
                  label={ filter.label }
                  field={ valuesKey }
                  value={ filter.values[valuesKey] }
                  filterName={ filter.filterName }
                  callBackFunction={ removeFilter }
                />

              // picto type filter
              : (
                filter.types[valuesKey] === 'variation'
                || filter.types[valuesKey] === 'movement' 
              ) ?
                <FilterRemoverPicto 
                  key={ index }
                  label={ filter.label }
                  field={ valuesKey }
                  value={ filter.values[valuesKey] }
                  filterName={ filter.filterName }
                  callBackFunction={ removeFilter }
                />

              // snippets type filter
              : filter.types[valuesKey] === 'snippets' ?
                <FilterRemoverSnippets
                  key={ index }
                  label={ filter.label }
                  field={ valuesKey }
                  value={ filter.values[valuesKey] }
                  filterName={ filter.filterName }
                  callBackFunction={ updateFilterValues }
                />

              // categories type filter
              : filter.types[valuesKey] === 'categories' ?
                <FilterRemoverCategories
                  key={ index }
                  label={ filter.label }
                  field={ valuesKey }
                  value={ filter.values[valuesKey] }
                  filterName={ filter.filterName }
                  callBackFunction={ updateFilterValues }
                />

              // default
              : null
            )
          )
        }

        {/* All filter remover */}
        {
          currentFilters.length >= 2 ?
            <FilterRemoverAll callBackFunction={ removeAllFilters } />
          : null
        }
      </div>
      : null
  )
}

export default FiltersRemover;