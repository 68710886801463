/** Dependencies */
import { SwitchButton } from 'smart-webcomponents-react/switchbutton';

/** SCSS */
import './ChartSelector.scss';

function ChartSelector()
{
  return(
    <div className="chart-selector">
      <div className='chart-selector-button-container impressions'>
        <SwitchButton inverted />
        <p className='label'>Impressions</p>
        <p className='value'>1345</p>
      </div>
      <div className='chart-selector-button-container clicks'>
        <SwitchButton inverted />
        <p className='label'>Clics</p>
        <p className='value'>1345</p>
      </div>
      <div className='chart-selector-button-container ctr'>
        <SwitchButton inverted />
        <p className='label'>CTR</p>
        <p className='value'>1345</p>
      </div>
      <div className='chart-selector-button-container position'>
        <SwitchButton inverted />
        <p className='label'>Position</p>
        <p className='value'>1345</p>
      </div>
      <div className='chart-selector-button-container occupation'>
        <SwitchButton inverted />
        <p className='label'>Occupation</p>
        <p className='value'>1345</p>
      </div>
      <div className='chart-selector-button-container snippets'>
        <SwitchButton inverted />
        <p className='label'>Snippets</p>
        <p className='value'>1345</p>
      </div>
    </div>
  )
}

export default ChartSelector;