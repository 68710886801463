/** Dependencies */
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

/** Helpers */
import { getPicto } from '../../../helpers/pictos';

/** SCSS */
import './GridCellPicTextPicLink.scss';

function GridCellPicTextPicLink( props )
{
  const {
    text,
    textLink,
    prePic,
    prePicOptions,
    prePicLink,
    postPic,
    postPicOptions,
    postPicLink
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'textlink-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // render element
  root.render(
    <Fragment>
      
      { // pre picture with or without link
        prePicLink !== undefined && prePic !== undefined ? 
          <a href={ prePicLink } className='pic-link' target='_blank' rel="noreferrer" title={ prePicLink }>{ getPicto( prePic, prePicOptions !== undefined ? prePicOptions : {} ) }</a>
        : prePic !== undefined ? 
          getPicto( prePic, prePicOptions )
        : null
      }

      { // text with or without link
        textLink !== undefined && text !== undefined ? 
          <a className='textlink-wrapper-text' href={ textLink } target='_blank' rel="noreferrer" title={ textLink }>{ text }</a>
        : text !== undefined ? 
          <p className='textlink-wrapper-text'>{ text }</p>
        : null
      }
      
      { // post picture with or without link
        postPicLink !== undefined && postPic !== undefined ? 
          <a href={ postPicLink } className='pic-link' target='_blank' rel="noreferrer" title={ postPicLink }>{ getPicto( postPic, postPicOptions !== undefined ? postPicOptions : {} ) }</a>
        : postPic !== undefined ? 
          getPicto( postPic, postPicOptions )
        : null
      }

    </Fragment>
  );

  return rootElement;
} 

export default GridCellPicTextPicLink;