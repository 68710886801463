/** Dependencies */
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

/** Components */
import InsightsCompareValues from './../../../components/Insights/InsightsCompareValues';

/** SCSS */
import './GridCellCompareValues.scss';

function GridCellCompareValues( props )
{
  const {
    value,
    variation,
    notation,
    style
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'comparevalues-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );  

  // render element
  root.render(
    <Fragment>
      { value !== null || variation !== null ?
        <InsightsCompareValues
          currentValue={ value }
          variation={ variation }
          classStyle={ 'grid-core-market flat' }
          notation={ notation }
          style={ style }
        />
        : null
      }
    </Fragment>
  );

  return rootElement;
}

export default GridCellCompareValues;