/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsExpectedUrl
{
  constructor(
    loaderID,
    devices,
    locations,
    dispatch,
    selectedInstance
  ){
    this.devices = devices;
    this.locations = locations;
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  getExpectedUrl = (
    keywords,
    callBackFct = null
  ) => {
    this.exec(
      keywords, 
      null,
      null,
      null,
      'get',
      callBackFct
    );
  }

  addExpectedUrl = ( 
    keywords, 
    expectedUrl,
    type,
    userId,
    callBackFct = null
  ) => 
  {
    this.exec(
      keywords, 
      expectedUrl,
      type,
      userId,
      'add',
      callBackFct
    );
  }
  
  exec = (
    keywords, 
    expectedUrl,
    type,
    userId,
    actionType,
    callBackFct = null
  ) => 
  {
    callWebservice( 
      this.loaderID,
      actionType + '-expected-url',
      actionType + '-expected-url',
      this.dispatch,
      this.selectedInstance,
      {
        where: actionType === 'get' ? 
          {
            keywords: Array.isArray( keywords ) ? keywords : [ keywords ], 
            devices: Array.isArray( this.devices ) ? this.devices : [ this.devices ],
            locations: Array.isArray( this.locations ) ? this.locations : [ this.locations ],
          }
          :{
            keywords: Array.isArray( keywords ) ? keywords : [ keywords ], 
            device: this.devices,
            location: this.locations,
          },
        expectedUrl: expectedUrl,
        typeExpectedUrl: type,
        userId: userId
      },
      callBackFct !== null ? {
        function: callBackFct
      } : null
    );
  }
}