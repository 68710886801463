/** Components */
import ChartSelector from "../ChartSelector/ChartSelector";
import InsightsCompareLineChart from "../Insights/InsightsCompareLineChart";

/** SCSS */
import './ChartDisplayer.scss';

function ChartDisplayer( props )
{
  return(
    <div className="chart-displayer-wrapper">
      <ChartSelector />
      <div className='chart-content'>
        <InsightsCompareLineChart 
          id = { 'test' }
          datas = { [
            {
                "xaxis": "nov.",
                "current": 34,
                "compareXaxisCaption": "nov.",
                "compare": null
            },
            {
                "xaxis": "déc.",
                "current": 29.5,
                "compareXaxisCaption": "déc.",
                "compare": null
            },
            {
                "xaxis": "janv.",
                "current": 30.5,
                "compareXaxisCaption": "janv.",
                "compare": null
            },
            {
                "xaxis": "févr.",
                "current": 33.2,
                "compareXaxisCaption": "févr.",
                "compare": null
            },
            {
                "xaxis": "mars",
                "current": 31.7,
                "compareXaxisCaption": "mars",
                "compare": null
            },
            {
                "xaxis": "avr.",
                "current": 32.8,
                "compareXaxisCaption": "avr.",
                "compare": null
            },
            {
                "xaxis": "mai",
                "current": 30.4,
                "compareXaxisCaption": "mai",
                "compare": null
            },
            {
                "xaxis": "juin",
                "current": 30.8,
                "compareXaxisCaption": "juin",
                "compare": null
            },
            {
                "xaxis": "juil.",
                "current": 29.4,
                "compareXaxisCaption": "juil.",
                "compare": 35.1
            },
            {
                "xaxis": "août",
                "current": 28.2,
                "compareXaxisCaption": "août",
                "compare": 36.4
            },
            {
                "xaxis": "sept.",
                "current": 28.4,
                "compareXaxisCaption": "sept.",
                "compare": 35.6
            },
            {
                "xaxis": "oct.",
                "current": 31.9,
                "compareXaxisCaption": "oct.",
                "compare": 34.7
            }
        ] }
          dataField = 'xaxis'          
        />
      </div>              
    </div>
  )
}

export default ChartDisplayer;