/** Dependencies */
import React, { useEffect, useState } from 'react';

/** Components */
import GridCore from '../GridCore.js';
import GridCellPicTextPicLink from './../../Cells/GridCellPicTextPicLink';
import GridCellCompareValues from './../../Cells/GridCellCompareValues';
import GridCellPictos from './../../Cells/GridCellPictos';

/** Helpers */
import { getMinMaxFromArrayObject, isValidJSON, isNullOrUndefined } from './../../../../helpers/functions.js';
import { sortByWithNullValue } from './../../../../helpers/sort.js';

/** SCSS */
import './GridCoreMarket.scss';

function GridCoreMarket( props )
{
  /** Get props */
  const {
    displayMode,
    loadingParams,
    gridSelectedKeywordsGroups,
    callBackFunction
  } = props;

  /** Init State */
  const [ dataSource, setDataSource ] = useState( null );
  const [ callBackParams, setCallBackParams ] = useState( null );
  
  /** Define page size */
  const pageSize = 16;

  /** Define default visible columns */
  const defaultVisibleColumns = [
    'label', 
    'volume_value', 
    'volume_variation', 
    'clicks_value', 
    'clicks_variation', 
    'position_value', 
    'position_variation',
    'select_columns'    
  ];

  /** Define data fields */
  const dataFields = [
    { name: 'label', dataType: 'string' },
    { name: 'volume_value', map: 'volume.value', dataType: 'number' },
    { name: 'volume_variation', map: 'volume.variation', dataType: 'number' },
    { name: 'clicks_value', map: 'gsc.clicks.value', dataType: 'number' },
    { name: 'clicks_variation', map: 'gsc.clicks.variation', dataType: 'number' },
    { name: 'position_value', map: 'serp.position.value', dataType: 'number' },
    { name: 'position_variation', map: 'serp.position.variation', dataType: 'number' },
    { name: 'SEO1PixPos_value', map: 'serp.SEO1PixPos.value', dataType: 'number' },
    { name: 'SEO1PixPos_variation', map: 'serp.SEO1PixPos.variation', dataType: 'number' },
    { name: 'SEORate_value', map: 'serp.SEORate.value', dataType: 'number' },
    { name: 'SEORate_variation', map: 'serp.SEORate.variation', dataType: 'number' },
    { name: 'rate_value', map: 'serp.rate.value', dataType: 'number' },
    { name: 'rate_variation', map: 'serp.rate.variation', dataType: 'number' },
    { name: 'snippets', map: 'serp.snippets', dataType: 'string' }
  ];

  /** Define data fields to load */
  const dataFieldsToLoad = [ 
    'keywords.followed',
    'keywords.groups',
    'volume',
    'gsc.clicks',
    'gsc.urls',
    'serp.position',
    'serpHtmlUrl',
    'serp.SEO1PixPos',
    'serp.SEORate',
    'serp.rate',
    'serp.snippets'
  ];

  /** Define grid columns properties */
  const gridColumns = [
    { 
      label: 'Mots-clés', 
      dataField: 'label',
      align: 'left',
      allowHide: false,
      width: '40%',
      cellsClassName: 'keywords',
      template: formatObject => {
        formatObject.template = GridCellPicTextPicLink( formatObject.value !== null ? 
          { 
            text: JSON.parse( formatObject.value ).value,
            postPic: JSON.parse( formatObject.value ).link !== undefined ? 'GoogleLink' : undefined,
            postPicOptions: JSON.parse( formatObject.value ).link !== undefined ? { width: 24, height: 24 } : undefined,
            postPicLink: JSON.parse( formatObject.value ).link
          }
          : null 
        );
      }
    },{ 
      label: 'Volume', 
      dataField: 'volume_value', 
      align: 'left',
      visible: defaultVisibleColumns.includes( 'volume_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'volume_variation', 
      showIcon: true,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'volume_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Clics', 
      dataField: 'clicks_value', 
      align: 'left',
      visible: defaultVisibleColumns.includes( 'clicks_value' ) ? true : false,
      sortOrder: 'desc',
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'clicks_variation', 
      showIcon: true,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'clicks_variation' ) ? true : false,        
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Pos. SERP', 
      dataField: 'position_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'position_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'position_variation', 
      showIcon: true,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'position_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'SEO Pix.', 
      dataField: 'SEO1PixPos_value', 
      align: 'left',
      visible: defaultVisibleColumns.includes( 'SEO1PixPos_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'SEO1PixPos_variation', 
      showIcon: true,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'SEO1PixPos_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Tx SEO', 
      dataField: 'SEORate_value', 
      align: 'left',
      visible: defaultVisibleColumns.includes( 'SEORate_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'SEORate_variation', 
      showIcon: true,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'SEORate_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Tx Occ.', 
      dataField: 'rate_value', 
      align: 'left',
      visible: defaultVisibleColumns.includes( 'rate_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'rate_variation', 
      showIcon: true,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'rate_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Snippets', 
      dataField: 'snippets', 
      width: 150,
      align: 'left',
      visible: defaultVisibleColumns.includes( 'snippets' ) ? true : false,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellPictos( 
          isValidJSON( formatObject.value ) ? 
            { ...JSON.parse( formatObject.value ), id: formatObject.row.id, pageSize: pageSize, index: formatObject.row.index } 
            : { value: null, id: formatObject.row.id, pageSize: pageSize, index: formatObject.row.index } 
          );
      }

    // fake column to have column chooser in header
    },{ 
      label: 'Choix des columns',
      className: 'hidden',
      dataField: 'select_columns',
      width: 50,
      visible: true,
      allowHide: false,
      allowSort: false
    }
  ];

  /** Format data source for Grid component */
  const formatDataSourceFct = response => response.map( elem => ({ 
    ...elem, 
    label: JSON.stringify( { value: elem.label, link: elem.serpHtmlUrl, keywordsGroups: elem.keywordsGroups } ), 
    volume: elem?.volume ? 
      { 
        value: elem.volume.currentVolume === null ? NaN : elem.volume.currentVolume, 
        variation: elem.volume.variation === null ? NaN : elem.volume.variation
      }
      : { value: null, variation : null }, 
    gsc: { 
      clicks : elem?.gsc?.clicks ? 
        { 
          value: elem.gsc.clicks.currentValue === null ? NaN : elem.gsc.clicks.currentValue, 
          variation: elem.gsc.clicks.variation === null ? NaN : elem.gsc.clicks.variation
        } 
        : { value: NaN, variation: NaN },
      urls: !isNullOrUndefined( elem?.gsc?.urls ) ? 
        JSON.stringify( { value: elem.gsc.urls } ) 
        : JSON.stringify( { value: null } )
    }, 
    serp: { 
      position : elem?.serp?.position ? 
        { 
          value: 
            elem.serp.position.currentValue === null ? 
              elem.serp.position.compareValue !== null ? 
                101
                : NaN 
              : elem.serp.position.currentValue,
          movement:
            ( elem.serp.position.currentValue !== null && elem.serp.position.compareValue === 101 ) ? 
              'in' 
            : ( elem.serp.position.currentValue === null && elem.serp.position.compareValue !== null ) ?
              'out'
            : null, 
          variation: 
            elem.serp.position.variation !== null ? 
              Math.floor( elem.serp.position.variation )
              : NaN                
        }
        : { value: NaN, variation: NaN },
      SEO1PixPos: elem?.serp?.SEO1PixPos ?
        {
          value: elem.serp.SEO1PixPos.currentValue === null ? NaN : -Math.round( elem.serp.SEO1PixPos.currentValue ), 
          variation: elem.serp.SEO1PixPos.variation === null ? NaN : Math.round( elem.serp.SEO1PixPos.variation )
        }
        : { value: NaN, variation: NaN },
      SEORate: elem?.serp?.SEORate ?
        {
          value: elem.serp.SEORate.currentValue === null ? NaN : elem.serp.SEORate.currentValue, 
          variation: elem.serp.SEORate.variation === null ? NaN : elem.serp.SEORate.variation
        }
        : { value: NaN, variation: NaN },
      rate: elem?.serp?.rate ?
        {
          value: elem.serp.rate.currentValue === null ? NaN : elem.serp.rate.currentValue, 
          variation: elem.serp.rate.variation === null ? NaN : elem.serp.rate.variation
        }
        : { value: NaN, variation: NaN },
      snippets:
        elem?.serp?.snippetsMe && elem?.serp?.snippetsOthers ?
          JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: elem.serp.snippetsOthers.currentValue } } )
        : elem?.serp?.snippetsMe ?
          JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: null } } )
        : elem?.serp?.snippetsOthers ?
          JSON.stringify( { value: { valueMe: null, valueOthers: elem.serp.snippetsOthers.currentValue } } )
        : JSON.stringify( { value: { valueMe: null, valueOthers: null } } ),
    }, 
    categories: elem?.categories?.category1 && elem?.categories?.category2 && elem?.categories?.category3 ?
      { value: elem.categories.category1 + '|' + elem.categories.category2 + '|' + elem.categories.category3 }
      : null      
  }));

  /** Update call back params */
  useEffect( () => 
  {
    if( dataSource !== null )
    {
      // call callback function with datas for parents elements
      setCallBackParams({
        ...callBackParams,
        keywords: dataSource.nonFilteredDataSource.length === dataSource.filteredDataSource.length ? 
          'followedKeywords'
          : dataSource.filteredDataSource.map( elem => JSON.parse( elem.label ).value ),
        selectedKeywords: dataSource.selectedKeywords,
        labels: dataSource.nonFilteredDataSource.map( elem => JSON.parse( elem.label ).value ),
        keywordsGroups: [ ...new Set( dataSource.nonFilteredDataSource.filter( elem => elem.keywordsGroups ).map( elem => elem.keywordsGroups ).flat() ) ],
        urls: [ ...new Set( 
          dataSource.nonFilteredDataSource.map( elem => 
            !isNullOrUndefined( elem?.gsc?.urls ) 
            && Array.isArray( JSON.parse( elem.gsc.urls ).value ) ? 
              JSON.parse( elem.gsc.urls ).value.map( elem => elem.url )
              : null 
          ).filter( elem => 
            elem !== null 
          ).flat()
        )],
        currentPeriod: dataSource.currentPeriod,
        comparePeriod: dataSource.comparePeriod,
        minMaxValues: getMinMaxFromArrayObject( 
          dataSource.nonFilteredDataSource, 
          [ 
            'volume.value',
            'gsc.clicks.value',
            'serp.position.value'
          ]
        )        
      });
    }

  }, [ dataSource ]);
  
  /** Send callback params with callback function */
  useEffect( () => 
  {
    if( typeof callBackFunction === 'function' && callBackParams !== null )
      callBackFunction( callBackParams );

  }, [ callBackParams ]);

  return(
    <GridCore
      coreType='market'
      gridColumns={ gridColumns }
      dataFields={ dataFields }
      loadingParams={{
        clientID: loadingParams.clientID,
        device: loadingParams.device,
        location: loadingParams.location,
        currentPeriod: loadingParams.currentPeriod,
        comparePeriod: loadingParams.comparePeriod,
        userId: loadingParams.userId,
        filters: loadingParams.filters,
        dataFieldsToLoad: dataFieldsToLoad
      }}
      defaultVisibleColumns={ defaultVisibleColumns }
      displayMode={ displayMode }
      gridSelectedKeywordsGroups={ gridSelectedKeywordsGroups }
      formatDataSourceFct={ formatDataSourceFct }
      callBackFct={ dataSource => setDataSource( dataSource ) }
      pageSize={ pageSize }
      columnHeight={ 72 }
      pagerHeight={ 50 }
      visibleHeader={ true }
    />
  )
}

export default GridCoreMarket;