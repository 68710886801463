/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from "react-router-dom";

/** Reducers */
import { offToggler } from './../../reducers/togglers';

/** Helpers */
import { sortArrayByObjectField } from './../../helpers/functions';

/** SCSS */
import './InstancesList.scss';

function InstancesList()
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get navigation path
  const pathname = useLocation().pathname;

  /** Get url params */
  const { clientID } = useParams();

  /** Instance useNavigate */
  const navigate = useNavigate();

  const onRowClick = ( nickname, devLoc ) => 
  {
    // get current screen
    const currentScreen = pathname.split( '/' )[1];

    // set new path
    const newPath = currentScreen + '/' + nickname + '/' + devLoc.countryValue + '/' + devLoc.deviceValue;

    // navigate to url with new path
    navigate( newPath );

    // close burger menu
    dispatch( offToggler( 'burgerMenuIsOpened' ) );
  }
  
  return(
    <div className="instances-list">
      {
        userDatas?.enabled_instances ? 
          <ul>{
            sortArrayByObjectField( structuredClone( userDatas.enabled_instances ), 'label' ).map( ( instance, index ) => 
              <li 
                key={ index }
                className={ clientID === instance.nickname ? 'selected' : '' }
                onClick={ () => onRowClick( instance.nickname, JSON.parse( instance.devLoc )[0] ) }
              >
                <img src={ instance.picture } alt={ instance.nickname } />
                { instance.label }
              </li>
            )
          }</ul>
          : null
      }
    </div>
  )
}

export default InstancesList;